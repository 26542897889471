import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_COMPONENTS = { action: 'change', subject: 'Components' }
  const tableColumns = [
    {
      key: 'action', label: '', thStyle: { width: can(ACCESS_ABILITY_COMPONENTS.action, ACCESS_ABILITY_COMPONENTS.subject) ? '10%' : '0%', padding: '5px 8px' }, tBodyThStyle: { width: '80%', padding: '5px 8px' },
    },
    { key: 'sku', label: 'SKU', thStyle: { width: '12%', padding: '5px 8px' } },
    { key: 'name', label: 'Name', thStyle: { width: '30%', padding: '5px 8px' } },
    { key: 'brand.name', label: 'Brand', thStyle: { width: '10%', padding: '5px 8px' } },
    { key: 'detail.model', label: 'Model', thStyle: { width: '15%', padding: '5px 8px' } },
    {
      key: 'qty', label: 'Quantity', thStyle: { width: '15%', padding: '5px 8px', textAlign: 'center' }, tBodyThStyle: { width: '17%', padding: '5px 8px' },
    },
  ]
  const catalogFields = {
    qty: {
      type: TEXT_INPUT,
      label: '',
    },
    qtyUpdate: {
      type: TEXT_INPUT,
      label: '',
    },
  }
  const autoSuggestTableColumns = [
    {
      key: 'name',
      label: 'Name',
      getValue: (value, data) => `${data.name}`,
    },
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'brand',
      label: 'Brand',
      getValue: (value, data) => `${data.brand.name}`,
    },
    {
      key: 'model',
      label: 'Model',
      getValue: (value, data) => `${data.detail.model}`,
    },
    {
      key: 'categories',
      label: 'Category',
      getValue(data) {
        return data.map(category => category.name).join(', ')
      },
    },
  ]
  return {
    tableColumns,
    catalogFields,
    autoSuggestTableColumns,
    ACCESS_ABILITY_COMPONENTS,
  }
}
