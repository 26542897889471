<template>
  <div class="pb-2">
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <l-table-list-collector
      v-if="!loader"
      ref="lTableRef"
      :is-footer="false"
      :is-search-input-disabled="isEdit"
      :module-name="CATALOG_MODULE_NAME"
      :fetched-data="fetchedSubmitData()"
      :table-columns="tableColumns"
      :item-id-for-check-itself="catalog.id"
      :table-suggestion-configs="autoSuggestTableColumns"
      autosuggest-list-item-name="name"
      :table-config-options="{
        endpoint: `${CATALOG_MODULE_NAME}/getComponents`
      }"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
      @getCollectedList="getCollectedList"
    >
      <template
        #cell(action)="{ data: { item } }"
      >
        <div
          class="d-flex align-items-center"
          style="gap: 12px"
        >
          <Can
            :i="ACCESS_ABILITY_COMPONENTS.action"
            :a="ACCESS_ABILITY_COMPONENTS.subject"
          >
            <button
              class="border-dashed btn p-0"
              :disabled="isEdit"
              @click="remove(item)"
            >
              <feather-icon
                icon="LTrashIcon"
                size="24"
                class="cursor-pointer border-dotted feather-trash-icon"
                style="height: 26px; width: 32px; border-radius: 5px;"
              />
            </button>
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer border-dotted feather-trash-icon"
              style="height: 26px; width: 32px;"
              @click="showProduct(item.id, $event)"
            />
          </Can>
        </div>
      </template>
      <template
        #cell(name)="{data: {item}}"
      >
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <feather-icon
            icon="LComponentIcon"
            size="24"
          />
          <div
            v-b-tooltip.noninteractive.hover
            class="item-name"
            :title="item.name ? item.name : '---'"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
      <template
        #cell(qty)="{ data }"
      >
        <div v-if="data.index === getTableRowIndex">
          <div
            class="d-flex justify-content-center"
            style="gap: 8px"
          >
            <div>
              <button-dashed
                type="button"
                class="button-dashed--disabled d-flex justify-content-center align-items-center m-0"
                :disabled="data.item.qty === minLimit || isEdit"
                style="height: 26px; width: 32px; padding: 0;"
                @click.stop="counterHandler(componentFormProducts, data.item, 'qty','minus')"
              >
                <feather-icon
                  icon="MinusIcon"
                  size="16"
                />
              </button-dashed>
            </div>
            <div>
              <b-form-input
                v-model="data.item.qty"
                class="custom-number-input"
                style="width: 42px; height: 26px"
                disabled
                @input="countInputHandler($event)"
              />
            </div>
            <div>
              <button-dashed
                type="button"
                class="button-dashed--disabled d-flex justify-content-center align-items-center m-0"
                :disabled="data.item.qty === maxLimit || isEdit"
                style="height: 26px; width: 32px; padding: 0;"
                @click.stop="counterHandler(componentFormProducts, data.item, 'qty')"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </button-dashed>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ data.item.qty }}
        </div>

      </template>
      <!--  Footer Cells    -->
      <template
        #foot(action)=""
      >
        <div>
          {{ $t('TOTAL') }}
        </div>
      </template>
      <template
        #foot(qty)=""
      >
        <div class="text-center">
          {{ sumTotal(componentFormProducts, 'qty') }}
        </div>
      </template>

    </l-table-list-collector>
    <div
      v-if="loader"
    >
      <b-table-skeleton :fields="tableColumns" />
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="!isFormChanged"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="cancel"
          >
            {{ $t('Back to List') }}
          </b-button>
          <b-button
            v-else
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="loaderThis"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
        </div>
        <div>
          <b-button
            v-if="$can(ACCESS_ABILITY_COMPONENTS.action, ACCESS_ABILITY_COMPONENTS.subject)"
            variant="success"
            class="saveBtn font-medium-1 font-weight-bolder"
            :disabled="!isFormChanged"
            @click="submit"
          >
            {{ $t('SAVE') }}
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import {
  BButton, BCol, BRow, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import config from '@/views/main/catalog/view/components/componentsConfig'
import { required } from '@/libs/validations/validations'
import store from '@/store'

export default {
  name: 'UpdateComponent',
  components: {
    LTableListCollector,
    ButtonDashed,
    BButton,
    BCol,
    BRow,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  data() {
    return {
      error: {},
      total: 0,
      totalRateSum: null,
      maxLimit: 999,
      minLimit: 1,
      submitData: [],
      collectedList: [],
      fetchedData: [],
      loader: false,
      required,
      getTableRowIndex: null,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    componentFormProducts() {
      return this.$store.state[this.MODULE_NAME].componentForm.components
    },
    components() {
      return this.$store.state[this.MODULE_NAME].componentForm
    },
    catalog() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
    componentsFormClone() {
      return this.$store.state[this.MODULE_COMPONENTS_CLONE].catalogComponentForm
    },
    isFormChanged() {
      return JSON.stringify(this.components) !== this.componentsFormClone
    },
  },
  created() {
    this.$emit('updateMenu', 'home-catalog-components')
    this.getComponents()
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, true)
    },
    sumTotal(listForSummation = [], itemQty) {
      return listForSummation?.reduce(
        // eslint-disable-next-line radix
        (accumulator, currentValue) => {
          if (currentValue[itemQty]) {
            return accumulator + +currentValue[itemQty]
          }
          return accumulator + 0
        },
        0,
      )
    },
    countInputHandler($event) {
      if (Number($event) >= 10000) {
        $event.preventDefault()
      }
    },
    sumRatesTotal(listForSummation = []) {
      if (!listForSummation.length) return
      // eslint-disable-next-line no-unused-vars
      let totalSum = 0
      const arrayOfRates = []

      for (let i = 0; i < listForSummation.length; i++) {
        const { rates, qty } = listForSummation[i]
        const validRates = rates.find(item => item.rate !== null)

        if (validRates) {
          arrayOfRates.push({ rate: validRates.rate, qty })
        }
      }
      totalSum += arrayOfRates?.reduce(
        // eslint-disable-next-line radix
        (accumulator, currentValue) => accumulator + (+currentValue.rate * currentValue.qty),
        0,
      )

      this.totalRateSum = totalSum
      // eslint-disable-next-line consistent-return
      return totalSum
    },
    fetchedSubmitData() {
      return this.components.components
    },
    rowHoveredHandler(data) {
      const { row } = data

      this.getTableRowIndex = row
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
    getComponents() {
      this.loader = true
      this.$store.dispatch(`${this.CATALOG_MODULE_NAME}/getComponent`, this.$route.params?.id)
        .then(res => {
          this.loader = false
          this.submitData = res.data.data
          this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, this.submitData)
          this.$store.commit(`${this.MODULE_COMPONENTS_CLONE}/SET_CATALOG_COMPONENT_FORM_CLONE`, this.submitData)
        })
        .catch(err => console.log(err))
    },
    submit() {
      const components = this.components.components?.map(item => ({
        component_id: item.id,
        qty: item.qty ?? 1,
      }))
      const { id } = this.catalog
      if (this.components.components.length > 0) {
        this.sendNotification(this, { components, id }, `${this.MODULE_NAME}/create`).then(() => {
          this.$store.commit(`${this.MODULE_COMPONENTS_CLONE}/SET_CATALOG_COMPONENT_FORM_CLONE`, this.submitData)
        }).catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.getComponents()
        })
      }
    },
    counterHandler(countNumbersArr, item, countItemKey = 'qty', countType = 'plus', countBy = 1) {
      // Distracting item
      const { id } = item

      // Finding handled item from countNumbersArr
      const getItem = countNumbersArr.find(countNumber => countNumber.id === id)
      // Converting Item Qty => Number Type
      getItem[countItemKey] = Number(getItem[countItemKey])

      // Checking countType
      if (countType === 'minus' && getItem[countItemKey]) {
        getItem[countItemKey] -= countBy
      } else if (countType === 'plus' && getItem[countItemKey] < 9999) {
        getItem[countItemKey] += countBy
      }
    },

    cancel() {
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    remove(data) {
      this.components.components = this.components.components.filter(component => component.id !== data.id)
      this.fetchedSubmitData()
    },
    showProduct(data) {
      const route = this.$router.resolve({ name: 'home-catalog-view', params: { id: data } })
      window.open(route.href)
    },

    getCollectedList(list) {
      this.components.components = list.map(item => ({
        ...item,
        qty: item.qty ? item.qty : 1,
      }))
    },

    loaderThis() {
      const smth = JSON.parse(this.componentsFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
  },
  setup() {
    const MODULE_NAME = 'components'
    const CATALOG_MODULE_NAME = 'catalogs'
    const GET_COMPONENT = 'getComponents'
    const MODULE_COMPONENTS_CLONE = 'cloneData'
    const {
      tableColumns, fields, catalogFields, autoSuggestTableColumns, ACCESS_ABILITY_COMPONENTS,
    } = config()

    return {
      tableColumns,
      MODULE_COMPONENTS_CLONE,
      catalogFields,
      MODULE_NAME,
      fields,
      CATALOG_MODULE_NAME,
      GET_COMPONENT,
      autoSuggestTableColumns,
      ACCESS_ABILITY_COMPONENTS,
    }
  },
}
</script>

<style scoped lang="scss">
.custom-number-input {
  margin: 0 auto;
  text-align: center;
  height: 30px;
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.button-dashed--disabled {
  &:disabled {
    opacity: 0.5;
  }
}

.item-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 24px;
}
</style>
